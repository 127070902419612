.custom-schedule .e-appointment {
  background-color: #ff0000; /* Specify your desired background color */
  color: #ffffff; /* Specify the text color */
}

html {
  scroll-behavior: smooth;
}

body {
  margin:0;
  padding:0;
  background-color: rgb(237, 246, 250);
  font-family: "Poppins", sans-serif;
  /* background-image: url('./assets/background/background.jpg'); */
}

  /* General Styling */
  .p-100 {
    padding: 0 100px;
  }
  .vh-60 {
    height: 60vh;
  }
  .vh-80 {
    height: 80vh;
  }
  .mw-800 {
    max-width: 800px;
  }
  .spinner-wrapper {
    min-height: 50vh;
    background-color: rgba(0, 0, 0, 0.2);
  }
  .text-justify {
    text-align: justify;
  }
  .cta-primary {
    padding: 5px 10px;
    color: rgb(160, 87, 160);
    font-size: 15px;
    border: 1px solid rgb(160, 87, 160);
    transition: .1s ease-out;
  }
  .cta-primary:hover {
    background-color: rgb(141, 59, 137);
    color: white;
  }
  .book-now-cta {
    border: 1px solid rgb(116, 22, 116);
    color: rgb(116, 22, 116);
    transition: .1s;
  }
  .book-now-cta:hover {
    background: rgb(116, 22, 116);
    color: #ffffff;
  }
  .demo-cta {
    background-image: linear-gradient(90deg, #464646, #000000, #464646);
    padding: 60px 0;
  }
  /* General Styling */

  /* General Animation */
  .fade-in {
    opacity: 0;
    transform: translateX(0); /* Default transform - no movement */
    transition: opacity 1s ease-out, transform 1s ease-out;
  }
  
  .fade-in.left {
    transform: translateX(-100px); /* Slide from left */
  }
  
  .fade-in.right {
    transform: translateX(100px); /* Slide from right */
  }
  
  .fade-in.bottom {
    transform: translateY(100px); /* Slide from bottom */
  }
  
  .fade-in.show {
    opacity: 1;
    transform: translateX(0) translateY(0); /* Original position */
    width: 100%;
  }

  @keyframes moveUpDown {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px); /* Move up by 20px */
    }
    100% {
      transform: translateY(0); /* Move back to original position */
    }
  }
  /* General Animation */
  /*---------------------------fms-----------------------------  */
.toc a{
  text-decoration: none;
  color: white;
}
.toc button{
  color: white;
  background-color: #6C757D;
  width: 100%;
  margin-bottom: 15px;
}
.toc button:hover{
  background-color: rgb(129, 63, 129);
}

.tableparent{
  width: 75%;
  margin: auto;
}
.fmstable a{
  text-decoration: none;
  color: black;
}
.fmstable{
  text-align: center;
}
.fmspic{
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 15px 25px 10px 1px rgba(108, 73, 124, 0.39);
}
.phcvspic{
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 15px 25px 10px 1px rgba(108, 73, 124, 0.39);
  width:60%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.articledetail {
  width: 75%;
  margin: auto;
}
.articledetail section{
  padding-top: 90px;

}
#faq .btn,#Conclusion .btn, #FAQS .btn{
  width: 100%;
}
#faq .btn:hover, #Conclusion .btn:hover{
  background-color: rgb(129, 63, 129);
}
.linkdemo{
  color: black;
}
.linkdemo:hover{
  color: rgb(173, 39, 173);
}

/*---------------------------end fms-----------------------------  */

/*---------------------------scheduler-----------------------------  */
#scheduler {
  width: 90%;
  height: 600px;
}
.schedule{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 100px;
  padding-top: 100px;
  min-height: 100vh;
}
/*---------------------------/scheduler-----------------------------  */
/* --------------------------------scrollable
design--------------------------------- */
::-webkit-scrollbar {
  width: 5px;
  height: 12px;
  }

  ::-webkit-scrollbar-track {
  border-radius: 12px;
  }

  ::-webkit-scrollbar-track:vertical {
  background: -webkit-linear-gradient(top, white, rgb(146, 184, 207));
  }

  ::-webkit-scrollbar-thumb {
  background: rgb(138, 84, 124);
  border-radius: 12px;
  }
  ::-webkit-scrollbar-thumb:hover {
  background: rgb(146, 102, 146);
  }
/* -------------------------------- end scrollable
design--------------------------------- */
/* ------------------------------------header
images----------------------------------- */


/* ------------------------------------ end header
images----------------------------------- */

/* Chatbot Starts */
.chatbot-form {
  display: flex;
  flex-direction: column;
  margin-top: 60px !important;

}

.chatbot-form .ediv, .chatbot-form .sign-div .ndiv{
  width: 100% !important;
}

.ediv {
  margin-bottom: 5px;
}

.sign-div {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
/* Chatbot Ends */

/* -----------------------------------homepage text design
------------------------------------------ */

/* Home New Design Starts */
.home-cta {
  background: #681f77;
  color: white;
  /* border: 2px solid transparent; */
  transition: .1s ease-out;
}
.home-cta:hover {
  color: white;
  background: #52165e;
  /* border: 2px solid #681f77; */
}

.home-text {
  padding: 100px 0;
}

  /* What We Offer*/
.what-we-offer {
  background-image: linear-gradient(90deg, #231557, #a326a3);
}

.what-we-offer .section-header p {
  max-width: 800px;
}

.offer-tile {
  max-width: 380px;
  height: 500px;
  border-radius: 15px;
  background-color: #fafafa;
  margin: 0 auto;
}

.offer-tile img {
  width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 5px;
  user-select: none;

}

  /* What We Offer*/
  /* Connect With Us */
.connect-with-us {
  width: 100%;
  height: 200px;
  background-color: #44107a;
  background-image: linear-gradient(90deg, #231557, #a326a3);
  margin-bottom: 500px;
}

.extended-background {
  height: 600px;
  width: 100%;
  background-image: url("./assets/home/connect-with-us-banner.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  clip-path: polygon(100% 0, 100% 80%, 0 100%, 0 20%);
  position: relative;
}

.extended-background::after {
  content: "";
  position: absolute;
  background-color: rgba(0, 0, 0, .5);
  inset: 0;
  clip-path: polygon(100% 0, 100% 80%, 0 100%, 0 20%);
  z-index: -1;
}

.connect-content {
  height: 100%;
  z-index: 2;
}

.connect-cta a {
  border: none !important;
  background-color: #EDF6FA !important;
  padding: 10px;
  color: #44107a !important;


}
.connect-cta a:hover {
  background-color: #f0e1ff !important;
}
  /* Connect With Us */
  /* Our Strong Partnership */
.our-strong-partnership p {
  max-width: 800px;
}
  /* Our Strong Partnership */
  /* Spotlight Tiles */
.spotlight-tiles,.spotlight-tile {
  overflow: hidden;
}
.spotlight-tiles .spotlight-tile {
  height: 500px;
}

.partners-logos {
  user-select: none;
}

.partner-logo {
  width: 100px;
  height: 100px;
  background-color: #EDF6FA;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.spotlight-img {
  background-color: #F4ECFD;
}

.partners-logos .partner-logo img {
  width: 70px;
}

.all-in-a-day img {
  width: 100%;
  animation: moveUpDown 10s ease-in-out infinite;
  user-select: none;
}
  /* Spotlight Tiles */
/* Home New Design Ends */

.buildingtech{
  letter-spacing: 3px;
  color: rgb(141, 45, 141);
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #aa3b98 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 6s linear infinite;
  display: inline-block;
}
@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.hometextp{
  letter-spacing: .5px;
  color: rgb(119, 55, 105);
}

.divimg{
max-width: 100%;
  overflow-x: auto;
}

#readmore video{
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
/* .getintouch{
  position: relative;
  z-index: 1;
} */

.getintouch {
  position: relative;
  z-index: 1;
  /* background: linear-gradient(-30deg, #662874 50%, #2b2222 50%); */
  padding: 10px 20px;
  margin: 12px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
  overflow: hidden;
  color: #844b82;
  font-size: 18px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-box-shadow: 0 20px 50px rgba(134, 96, 170, 0.5);
          box-shadow: 0 20px 50px rgba(126, 83, 169, 0.5);
}

.getintouch::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #a872c2;
  opacity: 0;
  -webkit-transition: .2s opacity ease-in-out;
  transition: .2s opacity ease-in-out;
}

.getintouch:hover::before {
  opacity: 0.2;
}

.getintouch span {
  position: absolute;
}

.getintouch span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, right top, left top,
from(rgba(43, 8, 8, 0)), to(#9a1f92));
  background: linear-gradient(to left, rgba(43, 8, 8, 0), #8a4ba7);
  -webkit-animation: 2s animateTop linear infinite;
          animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.getintouch span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left bottom, left top,
from(rgba(43, 8, 8, 0)), to(#ac4ed4));
  background: linear-gradient(to top, rgba(43, 8, 8, 0), #e453db);
  -webkit-animation: 2s animateRight linear -1s infinite;
          animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

.getintouch span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, left top, right top,
from(rgba(43, 8, 8, 0)), to(#a564d6));
  background: linear-gradient(to right, rgba(43, 8, 8, 0), #b249ab);
  -webkit-animation: 2s animateBottom linear infinite;
          animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

.getintouch span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left top, left bottom,
from(rgba(43, 8, 8, 0)), to(#bf43ef));
  background: linear-gradient(to bottom, rgba(43, 8, 8, 0), #c657d0);
  -webkit-animation: 2s animateLeft linear -1s infinite;
          animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}

/* Home Announcement Overlay Starts*/
.overlay-announcement-container {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  overflow: hidden;
}

/* Base styles for the dots */
.dot {
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dot.active {
  background-color: #4a124a; /* Color for the active slide */
}

/* Container for the indicators */
.overlay-indicators {
  display: flex;
  justify-content: center;
  gap: 8px;
}

/* Fade effect for the announcements */
.fade-content {
  opacity: 1;
  transition: opacity 0.3s ease-in-out; /* Smooth transition for fading */
}

.fade-content.fade {
  opacity: 0; /* Fades out when class is applied */
}

@media only screen and (max-width: 520px) {
  .overlay-announcement {
    height: 600px !important;
    width: 90% !important;
    padding: 40px !important;
  }
}
@media only screen and (max-width: 420px) {
  .overlay-announcement {
    height: 600px !important;
    width: 90% !important;
    padding: 20px 40px !important;
  }
  .page-header h1 {
    font-size: 2rem !important;
  }
}

.overlay-announcement {
  height: 600px;
  width: 500px;
  background-color: #ffffff;
  border-radius: 20px;
  position: relative;
}

.overlay-close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  transition: .1s ease-out;
}

.overlay-close:hover {
  opacity: .6;
}

.overlay-images img{
  height: 160px;
  width: 160px;
}

.overlay-images .ltfrb-logo{
  height: 158px;
  width: 158px;
}

.overlay-body p{
  text-align: justify;
  font-weight: 500;
  height: 145px;
  overflow: auto;
}

.overlay-cta button {
  background: rgba(141, 45, 141, 1);
  border: none;
  outline: none;
  border-radius: 10px;
  padding: 10px;
  transition: .1s ease-out;
}

.overlay-cta button:hover {
  background: rgba(141, 45, 141, .8);
}

.overlay-cta button:active {
  background: rgba(141, 45, 141, 0.6);
}
/* Home Announcement Overlay Ends*/
/* ----------------------------------- end homepage text
design------------------------------------------ */
/* ----------------------------------------navigation-------------------------------------
*/
.navigation {
  position: fixed;
  top: 0;
  left: 0;
  background: transparent !important;
  width: 100%;
  padding: 20px;
  transition: all 0.2s ease-in-out;
  z-index: 1000;
  user-select: none;

}
.navigation.sticky {
  padding: 0px 20px;
  background: #EDF6FA !important;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}
/* initial nav-link color */
.nav-link{
  color: #000000;
  transition: .1s ease-out;
  padding-bottom: 8px !important;
}
.nav-link:hover {
  /* color: #a326a3 !important; */
  opacity: .8;
}
.nav-link.active-menu{
  /* color: #a326a3 !important; */
  opacity: .8;
}
/* When scrolled */
.nav-link.sticky {
  color: #a326a3 !important;
  color: #000000 !important;
}

.nav-link.sticky:hover {
  color: #a326a3 !important;

}
.nav-item{
  margin-right: 80px;
}
.nav-link:hover{
  /* color: rgba(163, 38, 163, 0.6); */
  opacity: .8;
}
.dropdown .nav-link:focus {
  /* color: rgba(163, 38, 163, 0.6); */
  opacity: .8;
}

.dropdown-menu {
  /* background-color: #A326A3; */
  background-color: #EDF6FA;
  padding: 0px !important;
  position: relative;
  margin-top: 7px !important;
  transition: .1s ease-out;
}
.dropdown-menu::before {
  content: "";
  position: absolute;
  top: -7px;
  left: 0;
  width: 100%;
  height: 7px;
}
.dropdown-menu li a {
  /* color: #ffffff !important; */
  color: #000000 !important;
  font-weight: 500;
  padding: 10px;
}
.dropdown-item.active {
  background-color: rgba(163, 38, 163, 1) !important;
  color: #ffffff !important;
}
.dropdown-item:hover {
  /* background-color: rgba(255, 255, 255, .5) !important; */
  background-color: rgba(163, 38, 163, 1) !important;
  color: #ffffff !important;
}
/* .nav-item:hover{
  background-color: rgb(225, 154, 225);
}
.nav-link:hover {
  color: rgb(95, 86, 86);
}
.dropdown-item{
  color: rgb(163, 38, 163);
}
.dropdown-item:hover{
  background-color: rgb(225, 154, 225);
} */
.dropdown-item {
  transition: .1s ease-in;
}
.dropdown-item:hover {
  background: rgb(238, 190, 238);
}
.nav-link {
  width: fit-content;

}

.contact-us-btn {
  background: #53307d;
  border-radius: 20px;
  color: white !important;
  width: fit-content;
  cursor: pointer;
  transition: .1s ease-out;
}
.contact-us-btn a {
  text-decoration: none;
}
.contact-us-btn:hover {
  background: #673e99;
}

/* ---------------------------------------- end
navigation------------------------------------- */
/* ---------------------------------------nav pages
sizes--------------------------- */

#featured{
  margin-top: 80px;
}
/* .navigation{
  min-height: 20vh;
} */
.announcement{
  min-height: 50vh;
}
#client{
  margin-top: 120px;
  min-height: 50vh;
}
.aboutpagecard{
  max-width: 1200px;
  margin: auto;
}


/* ----------------------footer-------------- */
#footer{
  min-height: 60vh;
  background-color: rgb(74, 18, 74);
  position: relative;
}
.footer-brand img{
  max-width: 200px;
  max-height: 100px;
  user-select: none;
}
.fa-brands{
  font-size: 25px;
  color: white;
}


.footer-links ul li a {
  color: white;
  text-decoration: none;
}

.footer-links ul li a:hover {
  text-decoration: underline;
}

.footer-links p, .links p {
  font-size: 1.125rem;
}

.socials-icons a i{
  transition: .1s ease-out;
}

.socials-icons a:hover i{
  color: #67b0d4;
}

/* ----------------------end footer-------------- */

/* ---------------------------------------end nav pages
sizes--------------------------- */
/* --------------------------background animation-------------------------- */
.circles{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 100%; */
  min-height: 100vh;
  overflow: hidden;
}

.circles li{
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(187, 78, 206, 0.2);
  animation: animate 25s linear infinite;
  bottom: -150px;

}

.circles li:nth-child(1){
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}


.circles li:nth-child(2){
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3){
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4){
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5){
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6){
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7){
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8){
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9){
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10){
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}


@keyframes animate {

  0%{
      transform: translateY(0) rotate(0deg);
      opacity: 1;
      border-radius: 0;
  }

  100%{
      transform: translateY(-1000px) rotate(720deg);
      opacity: 0;
      border-radius: 50%;
  }

}
/* --------------------------end background
animation-------------------------- */

/* About Us Starts */
.about-timeline img {
  width: 100%;
}

.ceo-img img {
  width: 300px; 
  /* 350 */
}

.members-img img {
  width: 100%;
  max-width: 150px;
  /* 200 */
}

.about-introduction-bg {
  background-color: #EED8FF;
  padding: 20px;
  border-radius: 20px;
  font-weight: 500;
  margin: 0 auto;
}

.member-designation {
  font-weight: 500;
}

.members-img {
  user-select: none;
}
/* About Us Ends */



/* ---------------------------featured----------------------------------- */
.featuredtitle{
  letter-spacing: 3px;
  color: rgb(141, 45, 141);
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #aa3b98 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 6s linear infinite;
}

.ftitle .featuredtitle{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
.featuredminititle{
  color: rgb(116, 22, 116);
}
.featuredtitlebutn{
  padding: 5px 10px;
  color: rgb(160, 87, 160);
  font-size: 15px;
  /* border-bottom: 1px solid rgb(160, 87, 160); */
  border: 1px solid rgb(160, 87, 160);
  transition: .1s ease-out;
  /* position: absolute;
  bottom: 20px; */
}
.featuredtitlebutn:hover{
  background-color: rgb(141, 59, 137);
  color: white;
}
.featuredcard .card{
  background-color: rgba(255, 255, 255, 0.2);
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  /* box-shadow: 15px 25px 10px 1px rgba(108, 73, 124, 0.39); */
  height: 440px;
  position: relative;
}

/* ---------------------------end featured----------------------------------- */
/* ---------------------client---------------------- */
.clientbus{
  margin-top:80px;

}
.clientheadtext{
  letter-spacing: 3px;
  color: rgb(141, 45, 141);
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #aa3b98 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 6s linear infinite;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider {
  height: 100px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 80%;
}

.slider .slide-track {
  -webkit-animation: scroll 30s linear infinite;
  animation: scroll 30s linear infinite;
  display: flex;
  width: calc(250px * 14);
  user-select: none;

}

.slider .slide {
  height: 100px;
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider .slide .slide-bg {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  /* width: 160px; */
  width: 90%;
}

.slider .slide img{
  width: 90%;
  /* border-radius: 10px; */
}

/* .slider .slide .partnerimg {
  width: 84%;
  height: 100px;
  border-radius: 10px;
} */

@-webkit-keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}
/* ---------------------end client---------------------- */
/* ---------------------services------------------------- */
.servicecardrow{
  max-width: 1340px;
}
.servicecardrow .card{
   /* background-color: rgba(255, 255, 255, 0.2); */
   /*background: transparent;
   border: none !important; */
   /* box-shadow: 15px 25px 10px 1px rgba(108, 73, 124, 0.39); */
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.servicetitlebutn{
  padding: 2px;
  color: rgb(160, 87, 160);
  font-size: 15px;
  border-bottom: 1px solid rgb(160, 87, 160);

}
.servicetitlebutn:hover{
  background-color: rgb(141, 59, 137);
  color: white;
  border-radius: 25px;

}
/* --------------read more------------------- */

#readmore video{
  width: 68% !important;
  height: auto !important;
}

.readmorebtn{
  margin: auto;
  border: 1px solid rgb(160, 87, 160);
  border-radius: 5px;
  background: transparent;
  color: rgb(160, 87, 160);
  transition: .1s ease-out;
  padding: 5px 10px;
}
.readmorebtn:hover{
  background-color: rgb(141, 59, 137);
  color: white;
}
#readmore a {
  width: fit-content;
}
/* --------------end read more------------------- */


/* ---------------------end services------------------------- */
/* ----------------------------------- end about us
page------------------------------------------- */

/* ---------------------------Different
pages----------------------------------------------  */

/* ----------------------------------------about us
page------------------------------ */
.teamcard{
  max-width: 300px;
  max-height: 130px;
  box-shadow: 10px 5px 5px rgb(167, 141, 172);
  border-radius: 20px;

}
iframe{
  box-shadow: 15px 15px 15px rgb(167, 141, 172);

}

.teamcard .card-text, .teamcard .card-title{
  color: black;
  /* font-size: 88%; */
}
.teamcard img{
  min-height: 10px;
}

/* ----------------------------------------end about us
page------------------------------ */
/* ---------------------------team page--------------- */
.teampage .card{
  margin-top: -190px;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 15px 25px 15px 1px rgba(96, 52, 116, 0.39);
}
.teampage .card-text{
 text-align: justify;

}

.card p {
  font-size: .9rem;
}

.card h6 {
  font-size: 1.125rem;
}

.expertisecolumn{
  padding-top: 20px;
  font-size: 15.5px;
  border-top: 2px solid rgb(157, 64, 169);
}
.expertisecolumn li{
  /* list-style: none; */
  padding-bottom: 10px;
}
.bg{
  /* position: absolute; */
  padding-top: 100px;

  min-height: 60vh;
  background-color: #ffffff;
}

.main{
  min-height: 40vh;
}


/* ---------------------------end team page--------------- */

/* ----------------news page---------------------- */
/* #news{
  min-height: 100vh;  
  padding-top: 100px;
}

#news .card{
  width: 280px;
  height: 370px;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
} */

.news-header {
  background-image: url("./assets/page-banners/news-banner.png") !important;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.news-filter {
  background-color: #fafafa;
  width: 80%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  z-index: 9;
  margin-top: -60px;
  padding: 20px;
  border-radius: 20px;
}

.news-filter ul {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.news-filter ul li a {
  padding: 10px;
  /* border-radius: 20px; */
  font-weight: 500;
  text-decoration: none;
  color: black;
  transition: .1s ease-out;
  /* background-color: #EDF6FA;
  border: 1px solid transparent; */
  border-bottom: 1px solid transparent;
}

.news-filter ul li a:hover {
  border-bottom: 1px solid #231557;

}

.section-title {
  border-bottom: 1px solid #000000;
  padding-bottom: 10px;
}

/* .section-title a {
  color: #000000;
  text-decoration: none;
  transition: .1s ease-out;
} */

/* Removed .section-title h2 -Kian*/
.login-form h2 {
  font-size: 2.5rem;
  font-weight: bold;
}
.section-title h2 {
  font-weight: bold;
}



.card .card-image.small-featured img {
  max-width: 400px;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.card .card-description {
  font-size: 1rem;
}

.card .card-image img{
  width: 100%;
}

.card-info {
  width: 100%;
}
.card-image {
  width: 100%;
}

.big-card {
  height: 100%;
}

.big-card .card-info{
  width: 100%;
  height: 100%;
}

.big-card .card-image {
  height: 100%;
}
.big-card .card-image img {
  width: 100%;
  max-height: 250px;
  object-fit: cover;
  object-position: center;
}

.card .card-info h3 {
  font-size: 1.250rem !important;
}

.card .card-info .card-title {
  min-height: 48px;
}

/* For featured Cards on the right. Centered and Equal Heights */
.featured-right-cards:nth-child(1) {
  height: 49%;
}
.featured-right-cards:nth-child(2) {
  height: 49%;
}

/* News Category */
.news-category .card {
  min-height: 380px;
}
.news-category .card .card-image img{
  height: 150px;
  object-fit: cover;
  object-position: center;
}
/* News Category Ends */

/* ----------------end news page---------------------- */
/* Article Page Starts */
.article-overlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  inset: 0;
  z-index: 1;
}

.article-body {
  margin: 50px 0;
}
.article-body p{
  font-size: 1rem;
  text-align: justify;
}

.article-accordion {
  margin-bottom: 50px;
}

.accordion-item {
  border: none !important;
}

.accordion-button {
  box-shadow: none !important;
  font-size: 1.250rem;
  background: transparent !important;
  color: black !important;
  font-weight: 500;
}

.accordion-header, .accordion-body {
  background: #EDF6FA !important;
  border: none !important;
  font-size: 1.250rem;
}

.bottom-nav {
  padding: 0 100px;
  margin-bottom: 50px;
}
.bottom-nav a {
  color: black;
  font-size: 1.125rem;
} 

.swiper-slide {
  height: 400px;
}

.swiper-slide img {
  width: 100%;
}

/* Article Layout */
.right-image-article, .left-image-article {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.left-image-article {
  flex-direction: row-reverse;
}

.article-section{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 50px;
}

.article-body .article-text-wrapper,
.article-body .article-image-wrapper {
  flex: 1; /* Make both items grow equally */
  display: flex;
  align-items: stretch; /* Ensure equal height */
}

.article-body img {
  max-width: 100%;
  height: auto;
  object-fit: contain; /* Adjust image scaling within container */
}


/* Article Layout */

/* Article Page Ends */
/* ----------------news detail page---------------------- */
.newsdetail{
 padding-top: 100px;
 min-height: 100vh;

}
.newsdetail p{

  text-align: justify;
}
.newsdetail img{
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 15px 25px 10px 1px rgba(108, 73, 124, 0.39);
}
#newsdetail p{
  text-indent: 60px;
}
/* ----------------end news detail page---------------------- */
/* --------------------------srvice page-------------------------------- */
.service_page{
  padding-top: 100px;
  min-height: 100vh;
  position: relative;
}
.service_page a{
  margin-top: 500px;
}

.servicetext{
  margin: auto;
  max-width: 900px;
}
.servicetext .title{
  color: #231557;
}

.servicecontent img{
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 15px 25px 10px 1px rgba(108, 73, 124, 0.39);
}

.hidden {
  display: none;
}

.logoservices {
  transition: .1s ease-out;
}

.servicetitle {
  font-size: 1.25rem;
  font-weight: 500;
}

.servicesbutton:hover .logoservices {
  scale: 1.1;
}
.servicesbutton:hover .servicetitle {
  color: rgb(116, 22, 116);
}

.back-to-top:hover{
  filter: grayscale(50%);
}

/* Services Page (Individual Pages) Starts */
.afcs-content .content-text {
  width: 100%;
}

.afcs-content .headline {
  max-width: 600px;
  margin: 0 auto;
}

.circle-title p {
  font-size: .9rem;
}

.product-category-title {
  background-color: #673E99;
  color: white;
}

.product-box {
  max-width: 407px;
  height: 395px;
  /* background-color: #673E99; */

}

.product-name {
  background-color: #673E99;
  color: white;
}

.dash-bullet {
  width: 20px;
}

.product-specs {
  background-color: #EED8FF;
}

.feature-circle {
  width: 180px;
  min-width: 150px;
}

.circle-logo {
  background-color: #673e99;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.circle-logo ion-icon {
  width: 100%;
  font-size: 2rem;
  color: #ffffff;
}

.content-card-slider {
  position: relative;
  max-width: 300px;
  max-height: 250px;
  user-select: none;
}
.regular-card {
  transition: animation .2s ease-in;
  position: initial;
  pointer-events: none;
  cursor: pointer;
  /* opacity: 0; */
}
.discount-card {
  transition: animation .2s ease-in;
  pointer-events: none;
  scale: 1.03;
  cursor: pointer;
  /* opacity: 0; */

}

.regular-card.active {
  position: absolute;
  pointer-events: all;
  animation: swipe .8s ease;
  opacity: 1;
  z-index: 11;
}
.discount-card.active {
  position: absolute;
  left: 0;
  pointer-events: all;
  animation: swipe .8s ease;
  opacity: 1;
  z-index: 11;
}

@keyframes swipe {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(60px);
  }
  100% {
    transform: translateY(0px);

  }
}

.free-demo {
  width: fit-content;
}

/* EMS Page Starts */
.ems-hero {
  overflow: hidden;
}
.ems-hero-wrapper {
  height: 100%;
}
.ems-banner-img{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  user-select: none;
}

.ems-banner-img img {
  pointer-events: none;
}

.ems-banner-text h2 {
  font-size: 2.5rem !important;
}

/* .ems-banner-text a {
  background: #681f77;
  color: white;
  border: 2px solid transparent;
  transition: .1s ease-out;
}
.ems-banner-text a:hover {
  background: transparent;
  color: #681f77;
  border: 2px solid #681f77;
} */

.ems-box {
  width: 100%;
  background-color: red;
  margin-top: 20px;
  /* height: 300px; */
}

.ems-info h3, .ems-list-info h3, .ems-graphic-info h3, .ems-cta-info h3 {
  font-size: 2rem;
}

.ems-cta button {
    border: none !important;
    background-color: #44107a !important;
    color: #ffffff;
}
.ems-cta button:hover {
  background-color: #ffffff !important;
  color: #44107a;
}

.ems-info {
  background-color: #eed8ff;

}

/* .ems-list-info {
  background-color: #eed8ff;
} */

.ems-banner-text h2{
  font-size: 3rem;
}


.ems-graphic-info {
  background-color: #eed8ff;
}

.checklist ul li img {
  width: 30px !important;
}

.ems-graphic-info .graphic-header {
  max-width: 1000px;
}

.graphic-container {
  position: relative;
}

.ems-euodoo-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ems-euodoo-logo img {
  width: 200px;
}

.graphic-circle-info {
  display: none;
}
.graphic-circle-info.active {
  display: block;
  opacity: 1;
  position: absolute;
  width: 300px;
  height: 96px;
  background-color: #44107A;
  top: -8px;
  z-index: 2;
}
.graphic-circle-info.left {
  right: 15px;
  border-radius: 5px 50px 50px 5px ;
}
.graphic-circle-info.right {
  left: 15px;
  border-radius: 50px 5px 5px 50px ;
}

.graphic-circle-info.right span {
  margin-left: 105px !important;
}

.graphic-circle-info span {
  max-width: 180px;
  display: flex;
}

.graphic-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  position: relative;
}

.graphic-circle:hover .graphic-logo{
  scale: 1.2;
}

.graphic-circle.active .graphic-logo{
  scale: 1.2;
  z-index: 3;
}

.graphic-circle p {
  max-width: 126px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px !important;
  line-height: 1.3;
}

.graphic-logo {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #44107a;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .1s ease-in;
  cursor: pointer;
  user-select: none;
}

.graphic-logo img {
  width: 50px;
}

.top-circles, .bottom-circles {
  gap: 40px;
}
.upper-middle-circles, .lower-middle-circles {
  gap: 300px;
}

.ems-cta-info p {
  max-width: 1000px;
}

.ems-cta-info img {
  /* max-width: 600px; */
  width: 50%;
}

.cta-info h4 {
  font-size: 1.250rem;
}

.graphic-accordion {
  display: none;
}
/* EMS Page Ends */

/* Services Page (Individual Pages) Ends */

/* FMS Page Starts */
.fms-header h3 {
  font-size: 2.5rem;
  font-weight: bold;
}

.feature-box {
  height: 250px;
  width: 350px;
  background-image: linear-gradient(90deg, #2f1b7a, #802cc0);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  color: white;
  cursor: default;
}
.feature-box p {
  font-size: 1.250rem;
  font-weight: 500;
  user-select: none;
  pointer-events: none;
}

.feature-box img {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  object-fit: cover;
  object-position: center;
  opacity: 0;
  border-radius: 5px;
  transition: .1s ease;
  user-select: none;
  pointer-events: none;
}

.feature-box:hover img,
.feature-box:hover .feature-info {
  opacity: 1;
}

.feature-info {
  opacity: 0;
  color: white;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2;
  transition: .1s ease;
  user-select: none;
  pointer-events: none;
}

.telematic-info-box {
  background-color: #EED8FF;
  border-radius: 20px;
  margin: 0 auto;
}
.telematic-info-box h4 {
  font-weight: 600;
}
.telematic-img img {
  transition: .2s ease-out;
  user-select: none;

}
.telematic-img img:hover{
  scale: 1.1;
}
/* FMS Page Ends */
/* VAPT Page Starts */
.vapt-content-text {
  background-color: #EED8FF;
}

.vapt-content-text h3 {
  font-size: 2rem;
}
.vapt-content-text p{
  max-width: 600px;
  margin: 0 auto;
}

.certificate img{
  max-height: 400px;
  cursor: pointer;
  user-select: none;
}

.certificate {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.vapt-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  cursor: pointer;
}

.vapt-overlay-content img {
  margin: 0 auto;
  max-width: 30%;
  max-height: 30%;
  border-radius: 10px;
}

.certificate img.active {
  outline: 2px solid #231557;
  cursor: pointer;
}

/* VAPT Page Ends */
/* EMS Page Starts */
.ems-content-text h3 {
  font-size: 2rem;
}
.ems-content-text p{
  max-width: 600px;
  margin: 0 auto;
}
/* EMS Page Ends */
/* Euoride Page Starts */
.euoride-sub-header {
  z-index: 2;
  font-size: 1.125rem;
}

.client-app {
  /* background-image: linear-gradient(90deg, #231557, #a326a3); */
  background-image: url("./assets/services-page/euoride/euoridebg.png");

}
.euoride-content-text p{
  max-width: 600px;
  margin: 0 auto;
}

.app-feature {
  position: relative;
  background-color: #fa7ffa;
  width: 350px;
  min-width: 250px;
  height: 200px;
  border-radius: 10px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.app-feature p {
  font-size: 1.125rem;
}

.app-feature img {
  max-height: 150px;
  width: 100%;
  position: absolute;
  bottom: -15px;
  left: 0;
  right: 0;
  user-select: none;
}

.phone-container {
  height: 400px;
  width: 300px;
}

.phone-image {
  pointer-events: none;
  user-select: none;
}

.chev-left {
  position: absolute;
  top: 50%;
  left: -10px;
  transform: translateY(-50%);
  width: 40px;
  cursor: pointer;
  background-color: #231557;
  color: white;
  border-radius: 50%;
  padding: 5px;
  transition: .1s ease-in;
}
.chev-right {
  position: absolute;
  top: 50%;
  right: -10px;
  transform: translateY(-50%);
  width: 40px;
  cursor: pointer;
  background-color: #231557;
  color: white;
  border-radius: 50%;
  padding: 5px;
  transition: .1s ease-in;
}

.chev-left:hover, .chev-right:hover {
  background-color: #23155785;
}

.progress-wrapper {
  /* background-color: #fefefe; */
  margin-top: 20px;
  border-radius: 5px;
}

.swiper-description {
  width: fit-content;
}

.swiper-description p{
  border-radius: 5px;
  background-color: #231557;
}

.progress-container {
  overflow-x: auto;
}

.progress-wrapper .swiper-slide {
  height: initial;
}

.step-box {
  background-color: #ffffff;
  min-width: 200px;
  min-height: 100% !important;
  border-radius: 10px;
  cursor: pointer;
}

.step-title p {
  user-select: none;
}

.step-box.active {
  background-color: #231557;
  color: #ffffff;
  font-weight: 600;
}

/* Scrollbar */
.swiper-scrollbar-drag {
  background-color: #ebebeb !important;
}

/* Scrollbar background */
.swiper-horizontal > .swiper-scrollbar, .swiper-scrollbar.swiper-scrollbar-horizontal {
  background-color: #ffffff34;
}

.driver-feature {
  max-width: 700px;
  margin-top: -80px;
}
/* Euoride Page Ends */
/* -------------------------- end srvice page-------------------------------- */
/* ---------------------------coverage------------------------ */
.coverage{
  padding-top: 100px;
  min-height: 100vh;
}
.coverage .card{
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 15px 25px 10px 1px rgba(108, 73, 124, 0.39);
}
.coverage .card-img-top{
  width: 38%;

}

/* ---------------------------end coverage------------------------ */
/* --------------------------- Career Starts------------------------ */
#career .card-text{
  text-align: justify;
}
/* #career a{
  text-decoration: none;
  color: #50135f;
}
#career button{
  padding-top: 10px;
  border: 2px solid rgb(192, 161, 207);
  border-radius: 10px;
  background: transparent;
}
#career button:hover{
  background-color:  rgb(226, 216, 231);
}
#career img{
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 15px 25px 10px 1px rgba(108, 73, 124, 0.39);
} */

/* .career-body {
  min-height: 40vh;
} */

.career-description {
  background-color: #EED8FF;
}
.career-description p{
  max-width: 750px;
}
/* ---------------------------Career Ends------------------------ */
/* -----------------------------ContactForm-------------------- */
.form {
  width: 100%;
}
.ContactForm .formbutton{
  border-radius: 5px;
  padding: 10px 20px;
  transition: .1s ease-out;
  color: white;
  background-color: rgb(116, 22, 116);
}
.ContactForm .formbutton:hover{
  opacity: .8;

}

.page-header {
  background-image: linear-gradient(90deg, #231557, #a564d6);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #ffffff;
  position: relative;
  overflow: hidden;
}

.page-banner {
  position: absolute;
  user-select: none;
  inset: 0;
}

.page-header h1 {
  font-size: 2.5rem;
  margin: 0;
}

.form-title a {
  text-decoration: none;
  background-color: #231557;
  color: #ffffff;
  border: 2px solid transparent;
  padding: 10px;
  border-radius: 5px;
  font-weight: 500;
  transition: .1s ease-out;
  width: fit-content;
  margin: 0 auto;
}

.form-title a:hover {
  border: 2px solid #231557;
  background-color: transparent;
  color: #231557;
}

.form-title a ion-icon {
  font-size: 1.125rem;
}

.form-title p {
  font-size: 1.125rem;
}

.page-header h1, .page-header .sub-heading {
  z-index: 5;
}

.page-header h1 {
  max-width: 800px;
}

.sub-heading {
  max-width: 600px;
  text-align: center;
}

/* .contact-us-form {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: #EDF6FA;
} */

.contact-us-form textarea, .contact-us-form input {
  font-family: "Poppins";
  font-size: 1rem;
  border: none;
  transition: .1s ease-out;
  border-radius: 5px;
  padding: 10px;
  font-weight: 500;
  /* background-color: #EDF6FA; */
}
.contact-us-form textarea:focus, .contact-us-form input:focus {
  box-shadow: none;
  /* background-color: #EDF6FA; */
}

.contact-us-form input::placeholder, .contact-us-form textarea::placeholder {
  font-weight: 500;
}

.contact-us-form textarea {
  height: 120px;
}
/* .contact-us-form label {
  margin: 0 !important;
} */



.ContactForm {
  background: #EDF6FA !important;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* margin-top: 120px; */
}

.ContactForm > * {
  position: relative;
  z-index: 2; /* Ensure content is above the overlay */
}

.contact-container {
  width: 100%;
  padding: 50px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contact-container .contact-info{
  height: 50%;
  width: 100%;
}

.contact-container .contact-info .box{
  position: relative;
  padding: 20px 0;
  display: flex;
}

.contact-container .contact-info .box .icon{
  min-width: 50px;
  height: 50px;
  background: #EDF6FA;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  user-select: none;
}

.contact-container .contact-info .box .text{
  display: flex;
  margin-left: 20px;
  font-size: 16px;
  flex-direction: column;
  font-weight: 400;
}

.contact-container .contact-info .box .text h3{
  font-weight: 500;
  font-size: 20px;
}


/* .buttonalert{
  margin-left: 10%;
  margin-right: 10%;
} */
.contactheader{
  word-break: break-word;
  margin: 10px;
  padding: 20px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
  overflow: hidden;
  color: #f7f3f7;
  background-color: #EDF6FA;
  font-size: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-box-shadow: 0 20px 50px rgba(216, 201, 201, 0.5);
          box-shadow: 0 20px 50px rgba(194, 181, 181, 0.5);
}


.contactheader span {
  position: absolute;
}

.contactheader span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, right top, left top,
from(rgba(240, 175, 241, 0)), to(#f1eaf1));
  background: linear-gradient(to left, rgba(43, 8, 8, 0), #f7f6cc);
  -webkit-animation: 2s animateTop linear infinite;
          animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}
.contactheader span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left bottom, left top,
from(rgba(43, 8, 8, 0)), to(#ece4f0));
  background: linear-gradient(to top, rgba(43, 8, 8, 0), #e453db);
  -webkit-animation: 2s animateRight linear -1s infinite;
          animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

.contactheader span:nth-child(3) {
  bottom: 0px;
  left: 0px; 
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, left top, right top,
from(rgba(43, 8, 8, 0)), to(#a564d6));
  background: linear-gradient(to right, rgba(43, 8, 8, 0), #b249ab);
  -webkit-animation: 2s animateBottom linear infinite;
          animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

.contactheader span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left top, left bottom,
from(rgba(43, 8, 8, 0)), to(#bf43ef));
  background: linear-gradient(to bottom, rgba(43, 8, 8, 0), #c657d0);
  -webkit-animation: 2s animateLeft linear -1s infinite;
          animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}


/* -----------------------------end ContactForm-------------------- */
/* -------------announcement ------------------------------ */
.announcement1{
  margin-top: 100px;
}
.cardannouncement{
  margin-left: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 15px 25px 10px 1px rgba(108, 73, 124, 0.39);
}

.announcement{
  /* padding-top: 90px; */
  min-height: 100vh;
}
.announcementimg{
  background-color: rgba(255, 255, 255, 0.2);
  /* box-shadow: 15px 25px 10px 1px rgba(108, 73, 124, 0.39); */
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.announcementrow{
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
}
.announcementdetailpage{
  min-height: 100vh;
  padding-top: 100px;
}
.textarticle{
  text-align: justify;
}
.announcementdetailpage img{
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 15px 25px 10px 1px rgba(108, 73, 124, 0.39);
}
#menulinks{
  text-decoration: none;
  color: white;
  text-align: left;
}

#textmenu{
  margin: 20px;
}

#textmenu:hover{
  background-color: #3b2d42;
  color: rgb(250, 247, 247);
  border-radius: 5%;
}

/* Announcement table */
.announcement-table th:nth-child(1),
.announcement-table td:nth-child(1) {
    width: 75% !important;
}

.announcement-table th:nth-child(2),
.announcement-table td:nth-child(2) {
    width: 25% !important;
}

.announcement-uploads {
  width: fit-content;
}
/* -------------end announcement ------------------------------ */
/* --------------------webmail----------------- */
.webmail{
  padding-top: 100px;
  min-height: 100vh;
}
/* ----------with id--------------- */
.webmailpage{
  padding-top: 100px;
  min-height: 100vh;
}
/* --------------------webmail----------------- */

@media screen and (max-width: 1080px) {
  .all-in-a-day img {
    object-fit: cover;
    object-position: center;
    width: auto;
  }

  .partners-logos {
    padding: 0;
  }

  .partner-logo {
    width: 80px;
    height: 80px;
  }
  .partners-logos .partner-logo img {
    width: 50px;
  }

}
@media screen and (min-width: 1000px){
  .navbar-brand{
    margin-left: 80px;
  }

/* .aboutusimage{
  height: 110px;
} */
.aboutusimagestrat{
  height: 250px;
}
/* .contact-container{
  padding: 0 40px 0 40px;
} */
/* .ContactForm{
  padding: 25px 100px;
} */

}

@media screen and (max-width: 999px){
  .form-container{
    width: 300px;
  }
  #myForm{
    margin: 0px;
    margin-right: 2%;
  }
  .form-container .form-body{
    height: 450px;
  }

  .teamcard .card-text{
    font-size: 9px;
  }
  .teamcard .card-title{
    font-size: 12px;
    /* min-height: 48px; */
  }
  .coverage .card-img-top{
    width: 75%;

  }
  .contact-container{
    flex-direction: column;
  }
  .contact-container .contact-info{
    margin-bottom: 40px;
    width: 100%;
    word-break: break-word;
  }
  .contact-info, .contact-container, .form{
    width: 100%;
    padding: 0;
  }
  .form{
    margin-bottom: 40px;
  }
  /* .ContactForm{
    padding: 25px 70px;
  } */
}

/* For desktop view smooth dropdown animation */
@media (min-width: 992px) {
  .dropdown-menu {
      opacity: 0;
      transform: translateY(-10px); /* Move up slightly for smooth effect */
      transition: opacity 0.3s ease, transform 0.3s ease;
      display: block;
      visibility: hidden; /* Hidden by default */
  }

  .dropdown-menu.show {
      opacity: 1;
      transform: translateY(0); /* Move to original position */
      visibility: visible; /* Visible when hovered */
  }

    /* Add animation to the links inside the dropdown */
    .dropdown-menu .dropdown-item {
      opacity: 0;
      transform: translateY(-5px); /* Add slight movement for links */
      transition: opacity 0.3s ease, transform 0.3s ease;
  }

  /* When dropdown is shown, also animate the links */
  .dropdown-menu.show .dropdown-item {
      opacity: 1;
      transform: translateY(0); /* Links move back to original position */
  }
}

@media screen and (max-width: 991px){
  .p-100 {
    padding: 0;
  }
  /* .nav-item:hover{
    background-color: rgb(225, 154, 225);
    width: 100%;
  }
  .nav-link:hover {
    color: rgb(95, 86, 86);
    width: 100%;
  }
  .dropdown-item{
    color: rgb(163, 38, 163);
    width: 100%;
  }
  .dropdown-item:hover{
    background-color: rgb(225, 154, 225);
    width: 100%;
  } */
  #myForm{
    margin: 0px;
  }
  /* .open-button{
    margin-right: -20px;
  } */
  #close-pop{
    margin-right: -20px;
  }
  #badge{
    margin-right: -20px;
  }
  #_dialog_wrapper{
    margin: 20px;
    max-height: 430px !important;

  }
  .section2{
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  /* Nav mobile view */

  .navigation .show {
    transition: 2s ease-out !important;
  }

  .navigation.toggled {
    background: #EDF6FA !important;
    padding-bottom: 20px;
  }

  .navigation.home-nav .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
  }

  .navbar-toggler.menu-open .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
  }
  .navbar-toggler {
    border: none !important;
    box-shadow: none !important;
  }
  /* When scrolled, nav will be dark */
  .navigation.sticky .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
  }
  
  .contact-us-btn {
    padding: 10px;
  }

  /* About Us Mobile View */
  .ceo-img img {
    width: 300px;
    margin-bottom: 20px;
  }
  
  .members-img img {
    width: 100%;
    max-width: 200px;
  }


  /* Mobile View of Graphic in EMS Starts */
  .ems-euodoo-logo {
    position: static;
    top: auto;       
    left: auto;      
    transform: none; 
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }

  .top-circles, .bottom-circles, .upper-middle-circles, .lower-middle-circles {
    gap: 10px;
  }

  .vapt-overlay-content img {
    margin: 0 auto;
    max-width: 80% !important;
    max-height: 80% !important;
    border-radius: 10px;
  }
  /* Mobile View of Graphic in EMS Ends */
}

@media screen and (max-width: 658px) {
  /* Disable the clicks in the circles. */
  .ems-graphic-info-wrapper {
    position: relative;
  }

  .graphic-container, .graphic-circle {
    position: static;
  }

  .graphic-circle-info.active {
    bottom: 0px;
    top: unset;
  }
  
  .graphic-accordion {
    display: block;
    height: 100px;
  }

  .graphic-circle-info.left, .graphic-circle-info.right {
    /* right: unset;
    left: unset; */
    border-radius: 5px 5px 5px 5px ;
    left: 50%;
    bottom: 35px;
    transform: translateX(-50%);
  }

  .graphic-circle-info span {
    max-width: 100%;
    display: flex;
  }

  .graphic-circle-info.right span {
    margin: 0 !important;
  }


  .vapt-overlay-content img {
    margin: 0 auto;
    max-width: 80% !important;
    max-height: 80% !important;
    border-radius: 10px;
  }
}

@media screen and (min-width: 375px) and (max-width: 400px){
  /* .nav-item:hover{
    background-color: rgb(225, 154, 225);
    width: 100%;
  }
  .nav-link:hover {
    color: rgb(95, 86, 86);
    width: 100%;
  }
  .dropdown-item{
    color: rgb(163, 38, 163);
    width: 100%;
  }
  .dropdown-item:hover{
    background-color: rgb(225, 154, 225);
    width: 100%;
  } */
  #chatform{
    width: 350px;
  }
  #myForm{
    /* margin-bottom: 10px; */
    margin: 0;
    width: 350px;
    display: none;
    justify-content: center;
    align-items: center;
  }
  #chat-body{
    width: 320px;
    /* padding: 0px 5px 30px 5px; */
  }
  /* .ContactForm{
    padding: 25px 40px;
  } */
  /* .open-button{
    margin-right: -20px;
  } */
  #close-pop{
    margin-right: -20px;
  }
  #badge{
    margin-right: -20px;
  }
  .occupied-text{
    font-size: 10px;
  }
  .lunch-break span{
    font-size: 10px;
  }
  #_dialog_wrapper{
    width: 350px !important;
    max-height: 430px !important;

  }
  .section2{
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .menu-left {
    margin-top: 15%;
    order: 0;
  }
  .menu-right {
    order: 1;
    background-image: linear-gradient(to right, #A02EA2 0%, #A02EA2 50%, #A02EA2 50%,#A02EA2 100%) !important;
  }
  
}

@media screen and (min-width: 360px) and (max-width: 374px){
/* .nav-item:hover{
    background-color: rgb(225, 154, 225);
    width: 100%;
  }
  .nav-link:hover {
    color: rgb(95, 86, 86);
    width: 100%;
  }
  .dropdown-item{
    color: rgb(163, 38, 163);
    width: 100%;
  }
  .dropdown-item:hover{
    background-color: rgb(225, 154, 225);
    width: 100%;
  } */
  #chatform{
    width: 340px;
  }
  #myForm{
    padding: 0px 0px 0px 10px;
    width: 340px;
    display: none;
    justify-content: center;
    align-items: center;
  }
  #chat-body{
    width: 310px;
    /* padding: 0px 5px 30px 5px; */
  }
  /* .ContactForm{
    padding: 25px 40px;
  } */
  /* .open-button{
    margin-right: -30px;
  } */
  #close-pop{
    margin-right: -30px;
  }
  #badge{
    margin-right: -30px;
  }
  .overlay_2{
    margin-left: 3%;
  }
  .occupied-text{
    font-size: 10px;
  }
  .lunch-break span{
    font-size: 10px;
  }
  #_dialog_wrapper{
    width: 350px !important;
    max-height: 430px !important;

  }
  .status-p{
    font-size: 10px;
    margin-right: 12px;
  }
  .section2{
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .menu-left {
    order: 0;
  }
  .menu-right {
    order: 1;
    background-image: linear-gradient(to right, #A02EA2 0%, #A02EA2 50%, #A02EA2 50%,#A02EA2 100%) !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 359px){
  /* .nav-item:hover{
    background-color: rgb(225, 154, 225);
    width: 100%;
  }
  .nav-link:hover {
    color: rgb(95, 86, 86);
    width: 100%;
  }
  .dropdown-item{
    color: rgb(163, 38, 163);
    width: 100%;
  }
  .dropdown-item:hover{
    background-color: rgb(225, 154, 225);
    width: 100%;
  } */
  #chatform{
    width: 300px;
  }
  #myForm{
    width: 300px;
    display: none;
    justify-content: center;
    align-items: center;
  }
  .chat-body{
    width: 270px;
  }
  /* .ContactForm{
    padding: 25px 30px;
  } */
  /* .open-button{
    margin-right: -30px;
  } */
  #close-pop{
    margin-right: -30px;
  }
  #badge{
    margin-right: -30px;
  }
  .occupied-text{
    font-size: 10px;
  }
  .lunch-break span{
    font-size: 10px;
  }
  #_dialog_wrapper{
    width: 350px !important;
    max-height: 430px !important;

  }
  .status-p{
    font-size: 10px;
    margin-right: 12px;
  }
  .section2{
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .menu-left {
    order: 0;
  }
  .menu-right {
    order: 1;
    background-image: linear-gradient(to right, #A02EA2 0%, #A02EA2 50%, #A02EA2 50%,#A02EA2 100%) !important;

  }
}

@media screen and (min-width: 300px) and (max-width: 320px){
  /* .nav-item:hover{
    background-color: rgb(225, 154, 225);
    width: 100%;
  }
  .nav-link:hover {
    color: rgb(95, 86, 86);
    width: 100%;
  }
  .dropdown-item{
    color: rgb(163, 38, 163);
    width: 100%;
  }
  .dropdown-item:hover{
    background-color: rgb(225, 154, 225);
    width: 100%;
  } */
  #chatform{
    width: 280px;
  }
  #myForm{
    margin: 0px;
    width: 280px;
    display: none;
    justify-content: center;
    align-items: center;
  }
  #chat-body{
    width: 250px;
  }
  /* .open-button{
    margin-right: -20px;
  } */
  /* .ContactForm{
    padding: 25px 30px;
  } */
  /* .open-button{
    margin-right: -30px;
  } */
  #close-pop{
    margin-right: -30px;
  }
  #badge{
    margin-right: -30px;
  }
  .occupied-text{
    font-size: 10px;
  }
  .lunch-break span{
    font-size: 10px;
  }
  #_dialog_wrapper{
    width: 350px !important;
    max-height: 430px !important;

  }
  .status-p{
    font-size: 10px;
    margin-right: 12px;
  }
  .section2{
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .menu-left {
    order: 0;
  }
  .menu-right {
    order: 1;
    background-image: linear-gradient(to right, #A02EA2 0%, #A02EA2 50%, #A02EA2 50%,#A02EA2 100%) !important;

  }
}

@media screen and (min-width: 280px) and (max-width: 299px){
  /* .nav-item:hover{
    background-color: rgb(225, 154, 225);
    width: 100%;
  }
  .nav-link:hover {
    color: rgb(95, 86, 86);
    width: 100%;
  }
  .dropdown-item{
    color: rgb(163, 38, 163);
    width: 100%;
  }
  .dropdown-item:hover{
    background-color: rgb(225, 154, 225);
    width: 100%;
  } */
  #chatform{
    width: 262px;
  }
  #myForm{
    padding: 0px 0px 0px 10px;
    width: 260px;
    display: none;
    justify-content: center;
    align-items: center;
  }
  #chat-body{
    width: 230px;
  }
  /* .ContactForm{
    padding: 25px 30px;
  } */
  /* .open-button{
    margin-right: -30px;
  } */
  #close-pop{
    margin-right: -20px;
    width: 200px;
  }
  #badge{
    margin-top: 35px;
    margin-right: -30px;
  }
  .overlay_2{
    margin-left: 4%;
  }
  .renewDiv{
    width: 210px;
  }
  .lunch-break span{
    font-size: 10px;
  }
  #_dialog_wrapper{
    margin: 10px;
    max-height: 430px !important;

  }
  .section2{
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .menu-left {
    order: 0;
  }
  .menu-right {
    order: 1;
    background-image: linear-gradient(to right, #A02EA2 0%, #A02EA2 50%, #A02EA2 50%,#A02EA2 100%) !important;

  }
}

@media screen and (min-width: 1000px) and (max-width: 1300px){
  #_dialog_wrapper{
    max-height: 430px !important;

  }

}

/* --------------------- chat ------------------------ */
#careerlink{
  text-decoration: underline;
  color: white;
  border: none;
}

#link{
  /* margin: 20px 0px 10px 0px; */
  background-color: none;
  text-decoration: underline;
  background-color: transparent;
  color: #8d3c9d;
  border-bottom: #8d3c9d;
  border: none;
  font-size: 14px;
}

.renewDiv{
  padding: 6px;
  text-align: center;
  background-color: #fafafa;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  font-size: 1rem;
  font-weight: 500;
}

.renewDiv_2{
  text-align: center;
}

.chat-body{
  /* padding: 0px 5px 30px 5px; */
  padding: 0 5px;
}

#sForm {
  height: 400px;
  padding: 0px 5px 30px 5px;
}
#answerspan{
  background-color: #fdfdfd;
  color: black;
  border-radius: 10px 10px 10px 0px;
  text-decoration: none;
  padding: 7px;
  padding-left: 15px;
  margin-bottom: 10px;
  font-size: 1rem;
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate3d(0, -20%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.genans{
  background-color: #8d3c9d;
  color: white;
  border-radius: 10px 10px 10px 0px;
  text-decoration: none;
  padding: 7px;
  padding-left: 15px;
  margin-top: -10px;
}
.userq{
  background-color: white;
  color: #231557;
  border-radius: 20px;
  text-decoration: none;
  padding: 5px;
  margin: 5px;
}

#userspan{
  background-color: #e7bff0;
  color: black;
  border-radius: 10px 10px 0px 10px;
  padding: 10px;
  width: auto;
  margin: 0px;
  word-wrap: break-word;
  font-size: 1rem;
}
.open-button {
  cursor: pointer;
  position: fixed;
  display: inline-block;
  bottom: 40px;
  right: 40px;
  background-color: rgba(187, 78, 206, 0.2);
  /* background: #EDF6FA; */
  border-radius: 50%;
  border: 1px solid rgba(187, 78, 206, 1);
  padding: 10px 17px 12px 17px;
  width: 60px;
  height: 60px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: .1s ease-out;
}
.open-button:hover {
  opacity: .8 !important;
}

.o-button{
  position: fixed;
  display: inline-block;
  right: 50px;
  bottom: 80px;
  z-index: 10000;
}
.o-button #badge {
  font-size: 12px;
  position: absolute;
  display: inline-block;
  height: 19px;
  width: 19px;
  top: 70px;
  right: -15px;
  border-radius: 50%;
  color: white;
  visibility: hidden;
  text-align: center;
}
.chat-pop{
  background-color:#F1F8FB;
  border-radius: 5px;
  text-decoration: none;
  width: auto;
  right: 20%;
  position: relative;
  bottom: 0px;
  padding: 10px;
  font-size: 1.125rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 100;
}

#close-pop{
  /* visibility: hidden; */
  display: none;
}

.chat-message{
  line-height: 1.2;
  font-size: 14px;
  color: black;
}

.chat-pop .close{
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 10px;
  transition: .1s ease-out;
}

.close:hover{
  opacity: .4;
}


.img-logo {
  /* background-image: url(assets/logo/white-bg.png); */
  width: 45px;
  height: 45px;
  margin-right: 2%;
  background-color: rgb(74, 18, 74);
  border-radius: 50%;
  border: 1px solid rgb(74, 18, 74);
  padding: 3px 8px 8px 10px;
}

.time{
  font-size: .85rem;
  float: right;
}

.chat-tle{
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
  color: rgb(74, 18, 74);
  margin: 0px;
}

.chatDiv{
  margin-top: 20px;
}

/* The popup chat - hidden by default */
.chat-popup {
  display: none;
  position: fixed;
  bottom: 0;
  right: 40px;
  z-index: 12;
}

/* Add styles to the form container */
.form-container {
  width: 300px;
  /* padding: 10px; */
  background-color: #f0e8fae6;
  border-radius: 20px;
  margin-bottom: 110px;
  margin-right: -20px;
  border-radius: 10px 10px 10px 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  height: 460px;
}
.form-container .form-header{
  background-color: #53307D;
  border-radius: 10px 10px 0px 0px;
  padding: 0px;
  text-align: left;
  display: none;
  justify-content: space-between;
  align-items: center;
}

.form-container .form-body{
  margin: 10px;
  overflow: auto;
  height: 390px;
  top: 10px;
}

.form-body .title{
  color: #231557;
}

#title{
  font-size: 23px;
  font-weight: bold;
  color: #231557;
}

.btnq{
  border-radius: 5px 5px 5px 5px;
  background: #EDF6FA;
  border: 1px solid #67b0d4;
  padding: 7px;
  margin-bottom: 5px;
  color: #681f77;
  margin-left: 9%;
  width: 89%;
  text-align: center;
  /* opacity: 0;
  animation: fadeIn 1s ease-in-out both; */
  font-size: 14px;
  transition: .1s ease-out;
  font-size: 1rem;
}
.btnq:hover{
  background-color: #53307D;
  /* color: rgb(19, 18, 18); */
  color: white;
}
.chatlogo{
  margin-left: 1%;
  margin-top: 8px;
  margin-bottom: 8px;
}
hr{
  border: 1px solid #53307d;
}


.form-container .btn {
  background-color: #67b0d4; 
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  width: 100%;

  opacity: 0.8;
}

.form-container .cancel {
  cursor: pointer;
  margin-right: 5%;
  transition: .1s ease-out;
}

.cancel:hover{
  /* background-color: #d3b3d2; */
  opacity: .5;
}

.s-cancel {
  cursor: pointer;
  margin-top: 15px;
  float: right;
  margin-right: 5%;
  transition: .1s ease-out;
}

.s-cancel:hover{
  opacity: .4;
}

.form-container .btn:hover, .open-button:hover {
  opacity: 1;
}

.hidden {
  display: none;
}

.ans{
  margin-top: 2px;
}
.answerDiv{
  /* width: 95%; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  word-wrap: break-word;
}
.userDiv{
  display: flex;
  width: 98%;
  justify-content: flex-end;
  word-wrap: break-word;
  /* transform: translate(50%);
  opacity:0;
  animation: fade-in-right 0.5s ease-in-out both; */
}

.sectionChat{
  /* background: rgba(187, 78, 206, .6); */
  background-color: #53307D;
  height: 60px;
  border-radius: 10px 10px 0 0;
  position: relative;
}

#ediv, #ndiv{
  background: #EFE9FA;
  /* margin-bottom: 5px; */
  width: 225px;
}

#ediv input, #ndiv input {
  background: transparent;
  border-radius: 5px;
  border: 1px solid #b1b0b0;
  outline: none;
  padding: 10px;
  font-family: inherit;
  transition: .1s ease-out;
  font-weight: 500;
  font-size: 1rem;
}

#ediv input:focus, #ndiv input:focus {
  border: 1px solid #53307d;
}
.ein-field, .nin-field{
  font-size: 14px;
  width: 100%;
  border: 1px solid #b1b0b0;
}
.submit-chat{
  float: right;
  border: none;
  background: transparent;
  padding: 0 !important;
}

.submit-chat button {
  border: none;
  background-color: #741674;
  width: 100%;
  font-weight: 400;
  border-radius: 5px;
  padding: 5px;
  transition: .1s ease-out;
  color: #ffffff;
  font-weight: 500;
}

.submit-chat button:hover {
  opacity: .8;
}

.sign-div{
  display: flex;
}

.s-chatlogo{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading{
  display: flex;
  position: absolute;
  bottom: 5%;
  left: 4%;
  z-index: 999;
}
.loading > .circle{
  height: 12px;
  width: 12px;
  background-color: #844b82;
  margin: 5px;
  border-radius: 50%;
}
.circle-1{
  animation: blink 1s ease-in-out 0ms both infinite;
  float: left;
}
.circle-2{
  animation: blink 1s ease-in-out 200ms both infinite;
  float: left;
}.circle-3{
  animation: blink 1s ease-in-out 400ms both infinite;
  float: left;
}
@keyframes fade-in-right {
  0%{
    transform: translate(50%);
    opacity: 0;
  }
  100%{
    transform: translate(0%);
    opacity: 1;
  }
}
@keyframes blink {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
.hide{
  display: inline-block;
}
.overlay {
  position: absolute; 
  background: rgb(130, 97, 168);
  height: 410px;
  border-radius: 0px 0px 10px 10px;
  /* center overlay text */
  display: flex;
  align-items: center;
  justify-content: center;
}
.overlay_2 {
  width: 300px;
  left: 0;
  right: 0;
  bottom: 110px;
}
.rtext{
  font-size: 1rem;
  margin: 0px;
}

/* ------------------------- calendar ----------------------- */
/* .e-textlabel, DropDownListComponent{
  display: block;
} */

/* .quick-info-content td{
  padding: 0 5px;
}
.e-textlabel{
  color: #919191;
} */

.e-schedule .e-vertical-view .e-time-cells-wrap table td, .e-schedule .e-vertical-view .e-work-cells {
  height: 55px;
}

/* .quick-info-title{
  font-size: 18px;
} */

.custom-event-editor{
  width: 100%;
} 

.footer-image img{
  margin-left: -18px;
  margin-top: -15px;
}

.e-quick-popup-wrapper .e-event-popup .e-popup-footer { 
    display: unset; 
    float: right;
}

.e-quick-popup-wrapper .e-event-popup .e-popup-footer .e-event-edit {
  background-color: transparent;
}

.quick-info-header{
  background-color: white;
}
.quick-info-title{
  font-size: 16px;
  font-weight: 500;
}
.occupied-cell {
  color: white;
  font-size: 12px;
  text-align: center;
  background-color: gray;
  border-radius: 20px;
  padding: 3px;
}
.disabled-cell {
  pointer-events: none; /* Disable pointer events */
  opacity: 0.6; /* Apply an opacity to visually indicate the disabled state */
}
#Prefix_Error{
  top: 75px !important;
  left: 53% !important; 
}
#Status_Error{
  top: 145px !important;
  left: 53% !important; 
}
.event-content{
  font-weight: 500;
  line-height: 1.6;
  color: rgb(107, 106, 106);
}
.e-appointment{
  position: relative;
}
.e-appointment-details{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.lunch-break span{
  display: block;
}
.schedule-admin-legends{
  border-radius: 15px;
  border: 2px solid #cacaca;
  padding: 15px;
  margin-bottom: 20px;
  background-color: #fafafa;;
}
.Pending-box{
  width: 40px;
  background: #e3165b;
  height: 20px;
  margin-right: 5px;
  border: 1px solid black;
}
.Approved-box{
  width: 40px;
  background: rgb(163, 38, 163);
  height: 20px;
  margin-right: 10px;
  border: 1px solid black;
}
.Denied-box{
  width: 40px;
  background: gray;
  height: 20px;
  margin-right: 10px;
  border: 1px solid black;
}
.color-status{
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
}
.serviceBtn{
  margin-top: -50px;
  padding: 5px;
  color: rgb(160, 87, 160);
  font-size: 15px;
  border-radius: 20px;
  border: 2px solid rgb(160, 87, 160);
}

.eventManagement{
  padding-top: 60px;
  min-height: 100vh;
}

.banner-pic{
  margin-top: 10%;
  z-index:0;
  float:left !important;
  width:95%;
  position:relative;
  height:auto;
  margin-left:-5% !important;
}

.banner-pic1{
  float:right !important;
  position:relative;
  width: 100%;
  height: auto;
  /* margin-top: -30% !important; */
  margin-right:-10% !important;
}

.banner-pic2{
  float:right !important;
  position:relative;
  width: 80%;
  height:auto;
  margin-bottom: 10%;
  /* margin-left:-5% !important; */
}

.cvent-pic{
  float:right !important;
  width:90%;
  position:relative;
  height:auto;
  margin-right:-15% !important;
}

.colSection3{
  /* width:100%; */
  overflow:hidden;
}

 .column {
  float: left;
  padding: 10px;
  height: 550px;

}

.left {
  width: 40%;
}

.right {
  width: 60%;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
  margin-bottom: 100px;
}


.btnEvent{
  border: 2px solid #fff;
  color: #fff;
  background-color: transparent;
  border-radius: 5px;
  padding: 10px;
  margin-top: 2%;
  z-index:0;
}

.btnEvent:hover{
  background-image: linear-gradient(to right, #9B0FDD , #cc7dd4);
  border: 2px solid #fff;
}

.btnEvent2{
  border: 2px solid #A02EA2;;
  color: #fff;
  background-color: #A02EA2;;
  border-radius: 5px;
  padding: 10px;
  margin-top: 2%;
  z-index:0;
}

.btnEvent2:hover{
  background-image: linear-gradient(to right, #9B0FDD , #cc7dd4);
  border: 2px solid #fff;
}

.section2{
  /* padding: 200px; */
  min-height: 80vh;
  width: 70%;
  display: flex;
  align-items: center;
}

.section3{
  background-color:#F8F9FA;
  min-height: 80vh;
}


.section4{
  min-height: 80vh;
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section5{
  background-color:#F8F9FA;
  min-height: 115vh;
}

.section6{
  background-color:#F8F9FA;
  width: 100%;
}

.bubbles{
  position:absolute;
  width:100%;
  height: 100%;
  /* z-index:0; */
  overflow:hidden;
  top:0;
  left:0;
}
.bubble{
  position: absolute;
  bottom:-100px;
  width:40px;
  height: 40px;
  background:#f1f1f1;
  border-radius:50%;
  opacity:0.5;
  animation: rise 10s infinite ease-in;
}
.bubble:nth-child(1){
  width:40px;
  height:40px;
  left:10%;
  animation-duration:15s;
}
.bubble:nth-child(2){
  width:20px;
  height:20px;
  left:20%;
  animation-duration:5s;
  animation-delay:6s;
}
.bubble:nth-child(3){
  width:50px;
  height:50px;
  left:35%;
  animation-duration:7s;
  animation-delay:5s;
}
.bubble:nth-child(4){
  width:80px;
  height:80px;
  left:50%;
  animation-duration:11s;
  animation-delay:0s;
}
.bubble:nth-child(5){
  width:35px;
  height:35px;
  left:55%;
  animation-duration:6s;
  animation-delay:7s;
}
.bubble:nth-child(6){
  width:45px;
  height:45px;
  left:65%;
  animation-duration:8s;
  animation-delay:3s;
}
.bubble:nth-child(7){
  width:90px;
  height:90px;
  left:70%;
  animation-duration:12s;
  animation-delay:5s;
}
.bubble:nth-child(8){
  width:25px;
  height:25px;
  left:80%;
  animation-duration:6s;
  animation-delay:7s;
}
.bubble:nth-child(9){
  width:15px;
  height:15px;
  left:70%;
  animation-duration:10s;
  animation-delay:5s;
}
.bubble:nth-child(10){
  width:90px;
  height:90px;
  left:25%;
  animation-duration:10s;
  animation-delay:4s;
}
@keyframes rise{
  0%{
    bottom:-100px;
    transform:translateX(0);
  }
  50%{
    transform:translate(100px);
  }
  100%{
    bottom:1080px;
    transform:translateX(-200px);
  }
}

.rowBanner{
  min-height: 92vh;
}

/* .box{
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.box span{
  display: block;
  width: 30px;
  height: 30px;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: rotate(45deg);
  text-align: center;
  margin: -10px;
  animation: animate 2s infinite;
}

.box span:nth-child(3){
  animation-delay: -0.2s;
}

.box span:nth-child(2){
  animation-delay: -0.4s;
}

.box span:nth-child(1){
  animation-delay: -0.6s;
}

@keyframes animate
{
  0%{
  transform: rotate(45deg) translate(-5px, -5px);
  opacity:0;
  }
  50%{
    opacity:1;
  }
  100%{
  transform: rotate(45deg) translate(5px, 5px);
  opacity:0;
  }
} */

.eventUl {
  margin: 15px 0 0 32px;
  line-height: 2.0;
  list-style-image: url(https://img.icons8.com/ios-filled/30/2E51E1/instagram-check-mark.png); 
}

.bullet{
  top:-10px;
  position:relative;
}

.colCircle{
  position: absolute;
  width: 100%;
  height: 80%;
  background: #A02EA2;
  /* clip-path: circle(400px at right 80px); */
  transform: translateY(-50%);
  border-radius: 50%;
}

.circle-pic{
  width: 40%;
}

.skewed{
  display:flex;
  width: 45em;
  margin: 2em 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.7);
}

.skewedText{
  padding: 1.5em;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
}

.image{
  width: 50%;
  height: 17em;
  position: relative;
  overflow: hidden;
}

.image::before{
  content: '';
  position: absolute;
  left: -6.1em;
  transform: translateY(-50%) rotate(20deg);
  background: #111;
  width: 40%;
  height: 140%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
}

.vertical-align {
  display: table;
  text-align: center;
}
.child{
  display: table-cell;
  vertical-align: middle;
}
.section5Text{
  display: flex;
  justify-content: center;
}
.menu-right{
  background-image: linear-gradient(to right, #F8F9FA 0%, #F8F9FA 50%, #A02EA2 50%,#A02EA2 100%);
  overflow:hidden;
}


/* Hero Section */
.hero {
  height: 100vh;
}
.hero .video-container video {
  max-height: 100vh;
  min-height: 100vh;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
/* Hero Section */


/* Admin Login Form */

.login-form{
  width:500px;
  padding:4%;
}

.login-submit{
  background-color: #53307d;
  color: white;
  padding: 2% 5%;
  border: none;
  border-radius: 10px;
}

/* -----Admin Menu------ */

.sidebar {
  width: 270px;
  position: fixed;
  height: 100%;
  overflow: auto;
  border-right: 1px solid #e2e2e2;
  background-color: #ffffff;
  top: 0;
  left: 0;
  z-index: 999;
  transition: .2s ease;
}

.sidebar.active {
  left: -270px;
}

.admin-menu-btn.active {
  left: 30px;
}

.sidebar a{
  display: block;
  color: black;
  text-decoration: none;
  border-radius: 10px;
  margin: 2.5px 0;
}


.sidebar a:hover:not(.active) {
  background-color: rgba(164, 67, 214, 0.2);

}

.sidebar .links a i{
  width: 30px;
}

.admin-dropdown-button  i {
  width: 35px;
  margin-right: 5px;
}

.admin-menu-btn {
  width: 30px;
  cursor: pointer;
  transition: .2s ease-in-out;
  position: fixed;
  top: 45px;
  left: 210px;
  z-index: 1000;
}

.admin-menu-btn:hover {
  color: #A02EA2;
}

.admin-dropdown-button {
  display: flex;
  color: black;
  text-decoration: none;
  border-radius: 10px;
  margin: 2.5px 0;
  background-color: transparent;
  border: none;
}


/*  side content */

.side-content{
  /* margin-left: 30px; */
  padding: 2% 5%;
}

.news-display-list{
  border: 1px solid black;
  padding: 2%;
  border-radius: 15px;
  background-color: white;
  align-items: center;  
}

.image-container{   
  padding: 0;
  height: 100%;
  overflow: hidden;
}

.image-container img {
  width: 100%; 
  height: 100%;
  object-fit: cover; 
  object-position: center;
}

.create-news-btn{
  border: 1px solid black;
  background-color: white;
  text-decoration: none;
  padding: 10px;
}

.create-article{
  padding: 2%;
  /* background-color: white; */
}

.secondnav{
  display: flex;
  align-items: center;
  padding: 1.5%;
  border-bottom: 1px solid #e2e2e2;
  background-color: #ffffff;
}

.title-create{
  border: none;
  border-bottom: 1px solid #DEE2E6;
  width: 280px;
  font-weight: normal;
  font-size: 30px;
}

@media only screen and (min-width: 767.11px) {
  .title-create{
    margin-left: 90px !important;
  }
}

.create-article label {
  font-size: 18px;
  font-weight: 400px;
}

.article-component{
  padding: 2%;
  background-color: #ffffff;
  border-radius: 15px;
}

.dateSched .rs-picker-input-group{
  border: none;
}

.admin-header{
  display: flex;
  margin-top: 1%;
}

.nav-underline .nav-link {
  text-decoration: none; 
  padding-bottom: 5px; 
  border-bottom: 2px solid transparent; 
  transition: border-color 0.3s ease; 
}

.nav-underline .nav-link:hover {
  border-bottom-color: black; 
}

.nav-underline .nav-link.active {
  border-bottom-color: black;
}

.news-mini-nav{
  border: 1px solid #DEE2E6;
  background-color: white;
  padding-top: 1%;
  display: flex;
  align-items: center;
}

.admin-dropdown-menu {
  background-color: white;
  margin-top: 10px; /* Add space between the button and the dropdown menu */
  
}

.admin-dropdown-menu li{
  list-style: none;
}

.admin-dropdown-item {
  padding: 10px 15px;
  text-decoration: none;
  color: black;
  display: block;
}

.admin-dropdown-item:hover {
  background-color: #f0f0f0;
}

.articles-container{
  border: 1px solid #DEE2E6;
  background-color: white;
  width: 100%;
  border-collapse: collapse; 
}

.articles-container-head, .news-list{
  border-bottom: 1px solid #DEE2E6;
  display: flex;
  padding: 2%;
  align-items: center;
}

.news-list{
  padding: 2% 3%;
}

.create-article-btn{
  background-color: #A443D6;
  border: 2px solid #c682e8;
  color: white;
  padding: 5px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.create-article-btn:hover{
  background-color: #8e34bb;
}

.search-news{
  border: none;
  border-bottom: 1px solid black;
  margin-right: 20px;
}

.featured-title{
  text-decoration: none;
  color: black;
}

.recreate-file{
  max-width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
}

.custom-file-label{
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  width: 88%;
}

.recreate-chosen{
  background-color: #E9ECEF;
  min-width: 12%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #ccc;
  cursor: pointer;
  white-space: nowrap;
  padding: 0 2%;
}

.modern{
  min-height: 380px;
}

.modern .card-image img{
  height: 150px;
  object-fit: cover;
  object-position: center;
}

/* Not Found 404 starts */
.not-found-page {
  min-height: 40vh;
  margin-top: 100px;
}

.not-found-page h1 {
  font-size: 5rem;
}

.not-found-page a {
  text-decoration: none;
}
/* Not Found 404 Ends */